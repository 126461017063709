import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CimLoggingService } from '@cimdata/cim-logging';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { ArticleModel } from '../articles/article.model';
import { RolesProviderService } from '../auth/roles-provider-service';
import { LanguageService } from '../language/language.service';
import { ItemPriceModel } from '../price-determination/item-price.model';
import { SessionService } from '../session.service';
import { CartItemModel } from './cart-item-model';
import { CartModel } from './cart-model';
import { CartPersistenceService } from './cart-persistence.service';
import { CartSubmitModel } from './cart-submit.model';

@Injectable({
    providedIn: 'root',
})
export class CartService implements OnDestroy {
    private cartItems$ = new BehaviorSubject<CartItemModel[]>([]);
    private isDelivery$ = new BehaviorSubject<boolean>(false);
    private vatPerc = 19;
    private crrcy = 'EUR';
    private termsOfPayment = '';
    private orderNbr = '';
    private subscription: Subscription;

    private submittingInner$ = new BehaviorSubject<boolean>(false);

    constructor(
        private cartPersistenceService: CartPersistenceService,
        private sessionService: SessionService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private logger: CimLoggingService,
        private analyticsService: GoogleAnalyticsService,
        private rolesProviderService: RolesProviderService,
        languageService: LanguageService
    ) {
        // When language changes, we need to reload
        // This also triggers a reload when the user logs in
        this.subscription = languageService.getCurrentLanguage().subscribe(() => this.load());
    }

    public getIsSubmitting(): Observable<boolean> {
        return this.submittingInner$.asObservable();
    }

    public load() {
        if (!this.sessionService.getSessionSnapshot()) {
            return;
        }

        const data$ = combineLatest([
            this.cartPersistenceService.loadCart().pipe(take(1)),
            this.rolesProviderService.canSelectCollectionOnly(),
            this.rolesProviderService.canSelectDeliveryOnly(),
            this.rolesProviderService.isUsaCustomer(),
        ]);

        data$.subscribe(
            ([cart, collectionOnly, deliveryOnly, isUsaCustomer]) => {
                this.loadNewCart(cart);
                if (isUsaCustomer) {
                    this.isDelivery$.next(true);
                    return;
                }

                // Pre select delivery choice according to the rights
                if (deliveryOnly && !collectionOnly) {
                    this.isDelivery$.next(true);
                } else if (collectionOnly && !deliveryOnly) {
                    this.isDelivery$.next(false);
                }
            },
            err => {
                this.logger.error('Error loading cart', err);
                const errorMsg = this.translateService.instant('CART_LOADING_ERROR');
                this.snackbar.open(errorMsg, 'Ok');
            }
        );
    }

    public addToCart(
        article: ArticleModel,
        amount: number,
        price: ItemPriceModel,
        freightCosts: number
    ): CartItemModel {
        if (!article.buyable) {
            throw new Error('Article not buyable');
        }

        const cartItems = this.cartItems$.value;
        let cartEntry = cartItems.find(e => e.productNbr === article.nbr);

        if (cartEntry) {
            cartEntry.countUnit2 += amount;
            this.cartPersistenceService.changeItem(cartEntry, this.isDelivery$.value).pipe(take(1)).subscribe();
        } else {
            cartEntry = {
                ...article,
                ...price,
                countUnit2: amount,
                nbr: cartItems.length + 1,
                productNbr: article.nbr,
                productName: article.name,
                freightCosts,
                orderPos: (cartItems.length + 1) * 10,
                priceUnit1: article.unitPrice1Descr,
                priceUnit2: article.unitPrice2Descr,
            };

            this.cartPersistenceService
                .addToCart(cartEntry, this.isDelivery$.value)
                .pipe(take(1))
                .subscribe(
                    newCart => {
                        this.analyticsService.gtag('event', 'add_to_cart', {
                            currency: price.currencyCode,
                            item: {
                                item_id: article.nbr,
                                item_name: article.name,
                                item_category: article.productGroup,
                                item_subcategory: article.subProductGroup,
                                item_location: article.location,
                                price: price.singleNet,
                                quantity: amount,
                            },
                            value: price.totalNet,
                        });
                        this.loadNewCart(newCart);
                    },
                    err => {
                        this.logger.error('Error adding to cart', err);
                        const errorMsg = this.translateService.instant('CART_ADD_ERROR');
                        this.snackbar.open(errorMsg, 'Ok');
                        this.load();
                    }
                );
        }

        return cartEntry;
    }

    public changeEntry(itemNbr: number, newCount: number) {
        const cartItems = this.cartItems$.value;
        const item = cartItems.filter(i => i.nbr === itemNbr)[0];
        if (item) {
            item.countUnit2 = newCount;
            this.cartPersistenceService.changeItem(item, this.isDelivery$.value).subscribe(
                newCart => this.loadNewCart(newCart),
                err => this.logger.error('Error changing cart entry', err)
            );
        }
    }

    public changeEntryDiscount(orderPos: number, newDiscount: number) {
        this.cartPersistenceService.changeItemDiscount(orderPos, newDiscount).subscribe(
            newCart => this.loadNewCart(newCart),
            err => this.logger.error('Error changing cart item discount', err)
        );
    }

    public getCartItems(): Observable<CartItemModel[]> {
        return this.cartItems$.asObservable();
    }

    public getCartItemCount(): Observable<number> {
        return this.getCartItems().pipe(map(entries => entries.length));
    }

    public removeCartItem(nbr: number): void {
        const cartItems = this.cartItems$.value;
        const idx = cartItems.findIndex(e => e.nbr === nbr);
        if (idx < 0) {
            throw new Error('Entry could not be found');
        }
        const itemToDelete = cartItems[idx];

        this.cartPersistenceService
            .removeItem(itemToDelete)
            .pipe(take(1))
            .subscribe(
                newCart => {
                    this.analyticsService.gtag('event', 'remove_from_cart', {
                        currency: itemToDelete.currencyCode,
                        item: {
                            item_id: itemToDelete.nbr,
                            item_name: itemToDelete.productName,
                            item_category: itemToDelete.productGroup,
                            item_location: itemToDelete.location,
                            price: itemToDelete.singleNet,
                            quantity: itemToDelete.orderQuantity,
                        },
                        value: itemToDelete.totalNet,
                    });
                    this.loadNewCart(newCart);
                },
                err => {
                    this.logger.error('Error removing cart item', err);
                    const errorMsg = this.translateService.instant('CART_REMOVE_ERROR');
                    this.snackbar.open(errorMsg, 'Ok');
                    this.load();
                }
            );
    }

    public clearCart(): void {
        this.cartItems$.next([]);
    }

    public getIsDelivery(): Observable<boolean> {
        return this.isDelivery$.asObservable();
    }

    public setIsDelivery(isDelivery: boolean) {
        if (this.cartItems$.value.length > 0) {
            throw new Error('Can only be set with empty cart');
        }
        this.isDelivery$.next(isDelivery);
        this.analyticsService.gtag('event', 'toggle_delivery', { delivery: isDelivery });
    }

    public submitCart(
        request: CartSubmitModel,
        successCallback: () => void = () => {},
        errorCallback: (err) => void = () => {}
    ): void {
        this.submittingInner$.next(true);
        this.cartPersistenceService
            .book(request)
            .pipe(
                take(1),
                finalize(() => this.submittingInner$.next(false))
            )
            .subscribe(
                () => {
                    this.analyticsService.gtag('event', 'purchase', {
                        items: this.cartItems$.value.map(i => ({
                            item_id: i.nbr,
                            item_name: i.productName,
                            item_category: i.productGroup,
                            item_location: i.location,
                            price: i.singleNet,
                            quantity: i.orderQuantity,
                        })),
                        value: this.cartItems$.value.reduce((sum, curr) => sum + curr.totalNet, 0),
                    });
                    this.clearCart();
                    successCallback();
                },
                err => errorCallback(err)
            );
    }

    public get vatPercentage() {
        return this.vatPerc;
    }

    public get currency() {
        return this.crrcy;
    }

    public get order() {
        return this.orderNbr;
    }

    public get termOfPayment() {
        return this.termsOfPayment;
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private loadNewCart(cart: CartModel) {
        if (!cart) {
            this.isDelivery$.next(false);
            this.cartItems$.next([]);
            this.vatPerc = 19;
            this.crrcy = 'EUR';
            this.orderNbr = '';
            this.termsOfPayment = '';
        } else {
            this.isDelivery$.next(cart.isDelivery);
            this.cartItems$.next(cart.items);
            this.vatPerc = cart.vatPerc;
            this.crrcy = cart.currency;
            this.orderNbr = cart.order;
            this.termsOfPayment = cart.termsOfPayment;
        }
    }
}
